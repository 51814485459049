#Header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    .navbar {
        padding: 0.5rem 1rem;

        .container-fluid {
            .logo img {
                max-height: 50px;
                cursor: pointer;
            }
        }

        .navbar-toggler {
            border-color: #ffffff;

            &:focus {
                box-shadow: 0 0 10px 3px #d3d3d3;
            }
        }

        .collapse {
            @media screen and (max-width:991px) {
                position: absolute;
                width: 100%;
                top: 67px;
                background-color: rgb(255, 255, 255);
                left: 0;
            }

            .navbar-nav {
                margin-left: auto;
                align-items: center;
            }
        }

        .login-btn {
            background: #009ED8;
            padding: 8px 12px;
            color: #fff;
            border-radius: 5px;
            margin-left: 30px;
            font-weight: bold;
            cursor: pointer;
            width: 90%;

            @media screen and (max-width:991px) {
                margin-left: 0;
            }

            &:hover {
                background: #005BA2;
            }

            .nav-link {
                color: #fff;
                text-align: center;

                @media screen and (max-width:992px) {
                    width: 100%;
                }

                &:hover {
                    color: #ffffff;
                }
            }
        }

        .nav-item {
            white-space: nowrap;
        }

        .nav-link {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            margin: 0 10px;
            transition: color 0.3s;
            cursor: pointer;
            padding: 0;

            &:hover {
                color: #005BA2;
            }
        }
    }

    @media (max-width: 768px) {
        .navbar {
            padding: 0.5rem;

            .nav-link {
                font-size: 14px;
                margin: 5px 0;
            }

            .form-control {
                max-width: 200px;
            }
        }
    }

    @media (max-width: 576px) {
        .logo img {
            max-height: 40px;
        }

        .navbar {
            .nav-link {
                font-size: 12px;
                text-align: center;
            }

            .btn {
                font-size: 12px;
            }
        }
    }
}