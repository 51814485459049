* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    line-height: 1.5;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #009ED8;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: #009ed824;
}