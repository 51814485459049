#Footer {
    padding-top: 4rem;
    padding-bottom: 2px;
    background-image: linear-gradient(180deg, #005ba226 0%, #009ed824 100%);

    .container {
        .row {
            .footer-flex {
                .details {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    img {
                        width: fit-content;
                        height: 75px;
                        
                        @media screen and (max-width:500px) {
                            width: 103.5px;
                        }
                    }

                    p {
                        margin: 0;
                    }

                    .social-icon {
                        display: flex;
                        gap: 15px;
                        margin-bottom: 15px;

                        i {
                            position: relative;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 30px;
                            height: 30px;
                            border-radius: 5px;
                            cursor: pointer;
                            overflow: hidden;
                            transition: background-color 0.3s ease;
                            background-image: linear-gradient(to bottom, #009ED8, #005BA2);
                            text-decoration: none;
                            transition: all 0.3s ease;

                            &:hover {
                                transform: scale(1.05);
                                box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
                            }
                        }
                    }
                }

                .title {
                    .title-name {
                        p {
                            font-size: 16px;
                            font-weight: 600;
                            color: #005BA2;
                        }
                    }

                    .name {
                        .borders {
                            cursor: pointer;
                            position: relative;
                            transition: all 0.3s ease;

                            &::after {
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 0;
                                height: 1px;
                                background-color: #009ED8;
                                transition: width 0.3s ease;
                            }

                            &:hover::after {
                                width: 100%;
                            }

                            &:hover {
                                color: #009ED8;
                            }
                        }

                        .addres {
                            display: flex;
                            gap: 10px;

                            img {
                                width: 22px;
                                height: 22px;
                                cursor: pointer;
                            }

                            p {
                                cursor: pointer;

                                @media screen and (max-width:1023px) {
                                    word-break: break-word;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .copyright {
        display: flex;
        justify-content: space-evenly;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        border-top: 1px solid #ccc;
        gap: 30px;
        padding: 15px;

        .term {
            display: flex;
            gap: 15px;
            justify-content: center;

            p {
                cursor: pointer;

                &:hover {
                    color: #009ED8;
                    text-decoration: underline;
                }
            }
        }

        p {
            margin: 0;
        }

        @media screen and (max-width:767px) {
            flex-direction: column;
            gap: 20px;
        }
    }

    @media screen and (max-width:767px) {
        padding-top: 2rem;
    }
}